=anim($duration)
  transition: all $duration ease-in-out

// TVBox
=box($Z)
  border-radius: 6px
  background-color: white

  @if $Z == 100
    border: 1px solid transparentize($color-ink-lightest, 0.6)
    box-shadow: 0px 2px 6px -1px rgba(50, 55, 68, 0.48)

  @if $Z == 200
    box-shadow: 0px 6px 8px -1px rgba(23, 43, 77, 0.32)

  @if $Z == 300
    box-shadow: 0px 10px 16px -2px rgba(23, 43, 77, 0.32)

  @if $Z == 500
    box-shadow: 0px 24px 32px -6px rgba(23, 43, 77, 0.32)

=mq-modal
  @media only screen and (max-width: $device-m), (max-height: $device-s)
    @content

=mq-landscape
  @media (orientation: landscape) and (max-width: $device-xs), (orientation: landscape) and (max-height: $device-s)
    @content

=pseudo($display: block, $pos: absolute, $content: '')
  content: $content
  display: $display
  position: $pos

=flex_center
  display: flex
  align-items: center
  justify-content: center

=flex_sb
  display: flex
  align-items: center
  justify-content: space-between
