@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/sass/abstracts/mixins"









































































































































































$min_device_medium: 569px
$max_device_medium: 767px

=mq_modal_landscape
  @media (orientation: landscape) and (max-width: $device-xs), (orientation: landscape) and (max-height: $device-s)
    @content

.welcome-modal
  .tv-modal
    padding: 0 0 24px 0
    border-radius: 16px

    +mq_modal_landscape
      padding: 0
      border-radius: 0

    &__close
      display: none

    &__body
      height: 100%

    &__footer
      border-top: 1px solid #F0F0F2
      margin-top: 20px
      padding-top: 20px

      +mq-l
      background: white

      +mq_modal_landscape
        left: unset
        width: 65%
        right: 0
        box-shadow: none

  &__body
    display: flex
    flex-direction: column
    height: 100%
    max-width: 856px
    text-align: center

    +mq-xs
      min-height: 496px

    +mq_modal_landscape
      flex-direction: row
      text-align: left

  &__illustration
    display: flex
    height: 344px
    background-color: #0954AD

    +mq-s
      height: 220px

    +mq_modal_landscape
      width: 35%
      height: 100%

    @media (orientation: landscape) and (min-height: $min_device_medium) and (orientation: landscape) and (max-height: $max_device_medium)
      height: 260px

    img
      width: 100%
      height: 100%
      object-fit: cover
      object-position: center

  &__carousel
    +mq_modal_landscape
      width: 65%

  &__info
    display: flex
    flex-direction: column
    align-items: center
    box-sizing: border-box
    padding: 42px 24px 0 24px

    +mq-xs
      padding: 16px

    +mq_modal_landscape
      padding: 24px
      width: 100%
      align-items: start

    .info__title
      margin-bottom: 16px

      +mq-s
        font-size: 20px
        width: 232px

    .info__description
      max-width: 560px

  &__actions
    width: 100%
    display: flex
    padding: 0 32px

    +mq-m
      justify-content: space-between
      padding: 0 8px

    +mq_modal_landscape
      justify-content: space-between
      padding: 0 8px

    .actions__left
      width: fit-content

    .actions__right
      display: flex
      justify-content: center
      width: calc(100% - 196px)

      +mq-m
        justify-content: right
        width: auto

      +mq_modal_landscape
        justify-content: right
        width: auto

  .VueCarousel-dot
    margin-top: 0 !important
    background-color: $color-ink-lighter !important

    &--active
      background-color: $color-primary !important

    &-container
      +mq-xs
        margin-top: 0 !important

      +mq_modal_landscape
        margin-top: 0 !important

    &:focus
      outline: unset
